import { createSlice } from "@reduxjs/toolkit";

export const AgencySlice = createSlice({
  name: "agency",
  initialState: {
    checkedMap: {},
    showCheckboxAll: false,
    checkedAll: false,
    clickedAll: false, //手动选择全选
    currentPage: 1,
  },
  reducers: {
    setCheckedMap: (state, action) => {
      state.checkedMap = action.payload;
    },
    clearCheckedMap: (state) => {
      state.checkedMap = {};
      state.checkedAll = false;
      state.clickedAll = false;
    },
    setShowCheckboxAll: (state, action) => {
      state.showCheckboxAll = action.payload;
    },

    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setCheckedAll: (state, action) => {
      state.checkedAll = action.payload;
    },
    setClickedAll: (state, action) => {
      state.clickedAll = action.payload;
    },
  },
});
// 导出state
export const agency = (state) => state.agency;
// 导出actions
export const {
  setCheckedMap,
  clearCheckedMap,
  setShowCheckboxAll,
  setCurrentPage,
  setCheckedAll,
  setClickedAll,
} = AgencySlice.actions;
export default AgencySlice.reducer;
