import { createSlice } from "@reduxjs/toolkit";

export const HomeSlice = createSlice({
  name: "home",
  initialState: {
    appName: "CPlus",
  },
  reducers: {
    setAppName: (state, action) => {
      state.appName = action.payload;
    },
  },
});
// 导出state
export const home = (state) => state.home;
// 导出actions
export const { setAppName } = HomeSlice.actions;
export default HomeSlice.reducer;
