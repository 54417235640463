import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import "moment/locale/zh-cn";
import moment from "moment";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
moment.locale("zh-cn");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </Provider>
);

// serviceWorkerRegistration.register({
//   onUpdate: (config) => {
//     if (config?.waiting) {
//       config.waiting?.postMessage({ type: "SKIP_WAITING" });
//       window.location.reload();
//     }
//   },
// });

reportWebVitals();
