import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {},
    isFirstRequestVPerson: true,
    vPersonInfo: {},
  },
  reducers: {
    setUserInfo: (state, action) => {
      console.debug("user model:", action);
      state.userInfo = action.payload;
    },
    setVPersonInfo: (state, action) => {
      state.vPersonInfo = action.payload;
    },
    setIsFirstRequestVPerson: (state, action) => {
      state.isFirstRequestVPerson = action.payload;
    },
  },
});
// 导出state
export const user = (state) => state.user;
// 导出actions
export const { setUserInfo, setVPersonInfo, setIsFirstRequestVPerson } =
  UserSlice.actions;
export default UserSlice.reducer;
