import "./App.less";
import { routes } from "./routes";
import { HashRouter, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import JcToast from "./components/jc-toast";

class App extends React.Component {
  state = {
    isError: false,
    isLoading: false,
    showToast: false,
    toastContent: "",
    showMask: false,
    timeOut: 2300,
  };

  static getDerivedStateFromError(error) {
    return { isError: true };
  }

  componentDidMount() {
    window.addEventListener("message", (res) => {
      if (res) {
        let result = res.data.data;
        if (res.data.type === "showLoading") {
          this.setState({
            toastContent: "提交中，请稍等...",
            showToast: Number(result) === 1,
            showMask: true,
            timeOut: 0,
          });
        }
      }
    });
  }

  componentDidCatch(err, info) {
    console.log(err, info);
  }

  handleCose() {
    this.setState({ showToast: false });
  }

  render() {
    const { showToast, toastContent, isError, timeOut, showMask } = this.state;
    if (isError) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "180px",
            }}
          >
            <h3 style={{ textAlign: "center" }}>
              咦，页面开了小差，请刷新页面重新加载
            </h3>
            <p
              onClick={() => {
                window.location.reload();
              }}
              style={{
                marginTop: 10,
                padding: "5px 30px",
                border: "1px solid #1bbe8f",
                background: "#1bbe8f",
                borderRadius: 4,
                color: "#fff",
                textAlign: "center",
                cursor: "pointer",
                fontSize: "14px",
              }}
            >
              刷新页面
            </p>
          </div>
        </>
      );
    }
    return (
      <Suspense>
        <HashRouter>
          <Routes>
            {routes.map(({ path, component }) => (
              <Route
                key={path}
                path={path}
                element={component ? React.createElement(component) : null}
              />
            ))}
          </Routes>
        </HashRouter>

        {showToast ? (
          <JcToast
            showMask={showMask}
            timeOut={timeOut}
            onClose={this.handleCose.bind(this)}
            content={toastContent}
            visible={showToast}
          />
        ) : null}
      </Suspense>
    );
  }
}

export default App;
