import { createSlice } from "@reduxjs/toolkit";

/**
 *serviceApplicationFeeDetailDTO:[{
        acceptedQty: 0,
        accountItemCode: "",
        ccL2: "",
        ccL3: "",
        channelL2: "",
        channelL3: "",
        customizedSubposmCode: "",
        customizedSubposmName: "",
        estimatedBudgetNotax: "",
        estimatedBudgetTax: "",
        outletCodes: [],
        outletQty: 0,
        qty: 0,
        brandCode: "",
        brandName: "",
        supplierCode: "",
        taxRate: "",
        totalQuotationAmountNotax: "",
        totalQuotationAmountTax: "",
        unit: "",
      }],
 */
export const ServiceCustomizedSlice = createSlice({
  name: "serviceCustomized",
  initialState: {
    postingBrandCodes: [], //品牌分摊原始数据
    isCreate: true, // 是否是创建单子,
    // 预算渠道信息
    channelFeeInfo: {},
    oldPayload: {},
    channelL2List: [],
    channelL3List: [],
    checkedWaitList: {},
    // 基础信息
    serviceApplicationInfo: {
      // activityName: "", //活动名称
      // activityEndDate: "", //活动截止时间
      // activityStartDate: "", //活动开始时间
      // projectCode: "", //专项code
      // projectDesc: "", //专项描述
      // useFeeStartDate: "", //费用开始时间
      // useFeeEndDate: "", //费用结束时间
      // budgetAmountNotax: "", //预计不含税预算
      // salesOrgCode: "", //支付公司code
      // salesOrgName: "", //支付公司名称
      // planDesc: "", //方案描述
    },
    /**
     *  {
        kpiInfoDTO: {}, // 预测销量
        orderBrandListDTOs: [], //品牌分摊
      },
     */
    serviceApplicationSuborderDTOs: [], //多个子单
    serviceApplicationFeeDetailList: [
      //看顶部数据模型
    ],
    // 订单信息
    posmOrder: {},
  },
  reducers: {
    setServiceApplicationInfo: (state, action) => {
      state.serviceApplicationInfo = action.payload;
    },
    setServiceApplicationFeeDetailList: (state, action) => {
      state.serviceApplicationFeeDetailList = action.payload;
    },
    setChannelFeeInfo: (state, action) => {
      state.channelFeeInfo = action.payload;
    },
    setChannel2List: (state, action) => {
      state.channelL2List = action.payload;
    },
    setChannel3List: (state, action) => {
      state.channelL3List = action.payload;
    },
    setIsCreate: (state, action) => {
      state.isCreate = action.payload;
    },
    setPosmOrder: (state, action) => {
      state.posmOrder = action.payload;
    },
    setOldPayload: (state, action) => {
      state.oldPayload = action.payload;
    },
    setPostingBrandCodes: (state, action) => {
      state.postingBrandCodes = action.payload;
    },
    setServiceApplicationSuborderDTOs: (state, action) => {
      state.serviceApplicationSuborderDTOs = action.payload;
    },
    resetServiceAll: (state, action) => {
      state.postingBrandCodes = [];
      state.oldPayload = {};
      state.serviceApplicationFeeDetailList = [];
      state.serviceApplicationInfo = {};
      state.kpiInfoDTO = {};
      state.posmOrder = {};
      state.isCreate = false;
      state.channelFeeInfo = {};
      state.brandList = [];
      state.channelL2List = [];
      state.channelL3List = [];
      state.serviceApplicationSuborderDTOs = [];
    },
  },
});
export const serviceCustomized = (state) => state.serviceCustomized;
export const {
  setServiceApplicationSuborderDTOs,
  setServiceApplicationInfo,
  setServiceApplicationFeeDetailList,
  resetServiceAll,
  setChannel2List,
  setChannel3List,
  setChannelFeeInfo,
  setPosmOrder,
  setPostingBrandCodes,
  setOldPayload,
  setIsCreate,
} = ServiceCustomizedSlice.actions;
export default ServiceCustomizedSlice.reducer;
