import { createSlice } from "@reduxjs/toolkit";

export const PosmGroupSlice = createSlice({
  name: "posmGroup",
  initialState: {
    selectPosmList: [],
    selectClassGroup: {},
    selectPosmIds: [],
  },
  reducers: {
    setSelectPosmList: (state, action) => {
      state.selectPosmList = action.payload;
    },
    setSelectClassGroup: (state, action) => {
      state.selectClassGroup = action.payload;
    },
    setSelectPosmIds: (state, action) => {
      state.selectPosmIds = action.payload;
    },
    resetAllGroup: (state, action) => {
      state.selectPosmList = [];
      state.selectClassGroup = {};
      state.selectPosmIds = [];
    },
  },
});
// 导出state
export const posmGroup = (state) => state.posmGroup;
// 导出actions
export const {
  resetAllGroup,
  setSelectPosmList,
  setSelectClassGroup,
  setSelectPosmIds,
} = PosmGroupSlice.actions;
export default PosmGroupSlice.reducer;
