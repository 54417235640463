import { configureStore } from "@reduxjs/toolkit";
import home from "../pages/home/model";
import user from "../pages/user/model";
import outlet from "../pages/outlet/model";
import storeSingle from "../pages/outlet/single-customized/model";
import serviceCustomized from "../pages/outlet/service-customized/model";
import posmGroup from "../pages/outlet/components/choice-customized/model";
import agency from "../pages/order/list/audit/model";

const store = configureStore({
  reducer: {
    home,
    user,
    outlet,
    storeSingle,
    posmGroup,
    serviceCustomized,
    agency,
  },
});

export default store;
