import { createSlice } from "@reduxjs/toolkit";

export const OutletSlice = createSlice({
  name: "outlet",
  initialState: {
    detail: {},
    batchCustomized: {
      actionType: "", // add edit
      isCreate: true, // 是否是创建单子
      // 基础数据
      posmOrderDTO: {
        // activityName: "", // 活动名称
        // activityStartDate: "", // 开始日期
        // activityEndDate: "", // 结束日期
        // planDesc: "",
        // // 费用信息
        // projectCode: "", // 专项code
        // projectName: "",
        // useFeeStartDate: "", // 费用期间
        // useFeeEndDate: "",
        // salesOrgCode: "", // 支付公司
        // ccL2Code: "", // 二级预算中心
        // channelL2Code: "", // 二级渠道
        // channelL3Code: "", // 三级渠道
        // supplierCode: "", // 供应商code
        // supplierName: "",
        // demandDeliveryDate: "", // 需求交货日
        // budgetAmountNotax: "", // 预计不含税总预算
      },
      // 定制物料
      customizedPosm: [
        // {
        //   customizedPosmOrderCode: "", // 申请单编码
        //   customizedPosmCode: "", // 定制物料编码
        //   customizedPosmName: "",
        //   accountItemCode: "", // 费用项编码
        //   accountItemName: "",
        //   notes: "",
        //   subcategoryPosmListDTOs: [
        //     {
        //       customizedSubposmCode: "",
        //       customizedSubposmName: "",
        //       brandCode: "",
        //       brandName: "",
        //       qty: "",
        //       budgetAmountNotax: "",
        //     },
        //   ],
        //   singleStoreCustomizationDescListDTOs: [
        //     {
        //       pictureType: 2,
        //       pictureUrl: "",
        //     },
        //   ],
        // },
      ],
      // 补充说明图片
      customizedPosmSupplementaryPicDTOs: [],
      // 所有子物料品牌code
      subBrandCodeList: [],
      // 预测销量
      kpiInfoDTO: {},
      // 门店
      outletList: [],
      // 补充说明
      remark: "",
      // 物料订单信息
      posmOrder: {},
    },
  },
  reducers: {
    setOutletDetail: (state, action) => {
      state.detail = action.payload;
    },
    setBatchCustomized: (state, action) => {
      // console.log("setBatchCustomized.action", action.payload.data);
      state.batchCustomized[action.payload.type] = action.payload.data;
    },
    resetBatchCustomized: (state, action) => {
      state.batchCustomized.actionType = "";
      state.batchCustomized.posmOrderDTO = {};
      state.batchCustomized.customizedPosm = [];
      state.batchCustomized.customizedPosmSupplementaryPicDTOs = [];
      state.batchCustomized.subBrandCodeList = [];
      state.batchCustomized.kpiInfoDTO = {};
      state.batchCustomized.outletList = [];
      state.batchCustomized.posmOrder = {};
    },
  },
});
// 导出state
export const outlet = (state) => state.outlet;
// 导出actions
export const { setAppName, setBatchCustomized, resetBatchCustomized } =
  OutletSlice.actions;
export default OutletSlice.reducer;
