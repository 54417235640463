import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { Spin } from "antd";

/**
 * 全局Toast
 */
const zIndex = 1000;
const domNode = document.createElement("div");
domNode.setAttribute("name", "jc-toast");
document.body.appendChild(domNode);
const JcToast = ({
  visible,
  toastClass,
  timeOut = 2235,
  content,
  contentStyle = {},
  type,
  onClose,
  showMask = true,
}) => {
  let maskStyle = {};
  let _contentStyle = { ...contentStyle };
  useEffect(() => {
    if (timeOut > 0) {
      const timeoutId = setTimeout(() => {
        onClose && onClose();
      }, timeOut);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [content, visible, onClose, timeOut]);
  if (visible) {
    if (showMask) {
      maskStyle = {
        zIndex,
      };
    }
    _contentStyle = Object.assign(
      {
        zIndex,
      },
      _contentStyle
    );

    const renderToastItem = () => {
      if (content !== "") {
        let css = "jc-toast-info";
        if (type === 1) {
          css = "jc-toast-input-error";
        }
        if (toastClass) {
          css += " " + toastClass;
        }
        if (timeOut) {
          return <div className={css}>{content}</div>;
        } else {
          return (
            <div className={css}>
              <Spin style={{ color: "#f00" }} />
              <span style={{ marginLeft: 10 }}>{content}</span>
            </div>
          );
        }
      } else {
        return null;
      }
    };

    const toastDom = (
      <div className="jc-toast-box">
        {showMask ? (
          <div className={`jc-toast-mask`} style={maskStyle} />
        ) : null}
        <div className={`jc-toast-content`} style={_contentStyle}>
          {renderToastItem()}
        </div>
      </div>
    );
    return ReactDOM.createPortal(toastDom, domNode);
  } else {
    return null;
  }
};
export default JcToast;
